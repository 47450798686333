<template>
  <b-modal
    ref="modal-deal-sheet-import"
    hide-footer
    :title="titleExcel"
    id="modal-deal-sheet-import"
    no-close-on-backdrop
    size="xl"
  >
    <div>
      <b-row>
        <b-col>
          <div class="form-group">
            <span>
              <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
              format, hoặc có thể tải ở
              <a :href="urlExcel">đây</a>
            </span>
          </div>
        </b-col>
      </b-row>
      <span> <b>Bước 2:</b> Tiến hành Import </span>
      <b-row>
        <b-col>
          <UploadFileMulter
            v-on:send-file="getDataFromExcel"
            v-on:clear-file="clearFileExcel"
            v-on:send-error="getErrorMessage"
            :cmdUrl="'v2/deal-sheets/import-excel/'"
          />
          <span v-if="fileName">Tên file: <b>{{ fileName }}</b>
          </span>
        </b-col>
      </b-row>
      <!-- End input session -->
      <b-row>
        <b-table
          v-if="errorMessage.length"
          hover
          bordered
          :items="errorMessage"
          :fields="fieldsErrors"
          :per-page="10"
          :current-page="currentPageError"
        >
          <template #cell(status)>
            <span class="badge badge-warning">Đã tồn tại</span>
          </template>
          <template #cell(type)="row">
            {{ dealsheetTypeName[row.item.type] }}
          </template>
          <template #cell(startDate)="row">
            {{ formatDate(row.item.startDate, DATE_FORMAT) }}
          </template>
          <template #cell(endDate)="row">
            {{ formatDate(row.item.endDate, DATE_FORMAT) }}
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                no-caret
                right
              >
                <template slot="button-content">
                  <i class="flaticon2-settings"></i>
                </template>
                <b-dropdown-item @click="viewDetails(row.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen"
                    ></i>
                    &nbsp; Xem chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteDealSheet(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-row>
      <b-row>
        <b-col>
          <b
            v-if="errorMessage.length"
            class="mt-3 text-dark"
          >
            Tổng số:
            {{ errorMessage.length }}
          </b>
        </b-col>
        <b-col>
          <b-pagination
            class="custom-pagination"
            v-show="errorMessage.length > 10"
            v-model="currentPageError"
            :total-rows="errorMessage.length"
            :per-page="10"
            align="right"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <div
      class="mt-10"
      v-show="importItems.length !== 0"
    >
      <hr
        class="hr-text"
        data-content="Danh sách dealsheet"
        style="font-weight: 600"
      />
      <b-table
        class="myTable"
        caption-top
        responsive
        bordered
        hover
        :fields="fields"
        :items="importItems"
        id="my-table"
        :per-page="10"
        :current-page="currentPage"
        :busy="isBusy"
      >
        <template #cell(index)="data">
          {{ data.item.index }}
        </template>
        <template #cell(type)="data">
          {{ dealsheetTypeName(data.item.type) }}
        </template>
        <template #cell(isActive)="data">
          <span v-text="dealsheetStatusName(data.item.isActive)">
          </span>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle ml-3"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(statusValidate)="data">
          <div v-if="!data.item.onLoading">
            <div
              class="d-table-cell align-middle"
              style="width: 10%"
            >
              <i
                v-b-tooltip.hover
                :title="htmlGetStatusValidate(data.item, 'title')"
                :class="htmlGetStatusValidate(data.item, 'class')"
              ></i>
            </div>
            <div
              class="d-block align-middle pb-1 pl-2"
              style="width: 90%"
            >
              <div
                v-for="(items, i) in data.item.errors"
                :key="i"
              >
                <span class="text-danger">{{ items }}</span>
              </div>
            </div>
            <div
              class="d-table-cell align-middle pb-1 pl-2"
              style="width: 90%"
            >
              {{ data.item.statusValidateName }}
            </div>
          </div>
          <div v-else>
            <b-spinner
              small
              variant="primary"
              class="mr-2"
            ></b-spinner>
            <span class="text-primary">Đang xử lí...</span>
          </div>
        </template>

        <template v-slot:cell(actions)="row">
          <i
            class="flaticon2-rubbish-bin-delete-button text-danger"
            @click="showDeleteAlert('Xoá', `Bạn có chắc muốn xoá ${row.item.code}`, row.item, conFirmDel)"
            role="button"
          >
          </i>
        </template>
      </b-table>
      <b-col class="row mt-3">
        <b>
          Tổng số:
          {{ importItems.length || 0 }}
        </b>
      </b-col>
      <b-pagination
        class="custom-pagination"
        v-show="importItems.length > 10"
        v-model="currentPage"
        :total-rows="rows()"
        :per-page="10"
        aria-controls="my-table"
        align="right"
      ></b-pagination>
    </div>
    <b-button
      style="width: 80px"
      class="mt-6 mr-2"
      v-if="importItems.length && !errorMessage.length"
      variant="primary"
      size="sm"
      @click="onCreateDealSheetThrottle()"
    >
      <strong>Tạo mới</strong>
    </b-button>
    <b-button
      class="mt-6"
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="hideModalExcel"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>
<script>
import { BASE_URL } from '@/utils/constants';
import { cloneDeep } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import localData from '@/utils/saveDataToLocal';
import { makeToastSuccess, makeToastFaile } from '../../../utils/common';
import { DEALSHEET_TYPE_NAME, DEALSHEET_STATUS_NAME } from '@/utils/enum-name';
import _ from 'lodash';
import { showDeleteAlert } from '@/utils/sweet-alert2.js';
import { formatDate } from '@/utils/date';
import { DATE_FORMAT } from '@/utils/constants';
const STATUS_VALIDATE = {
  INVALID: 0,
  VALID: 1,
  READY: 2,
  LOADING: 3,
};

export default {
  name: 'DealSheetImportExcel',
  data() {
    return {
      dealsheetId: '',
      DATE_FORMAT: DATE_FORMAT,
      currentPageError: 1,
      errorMessage: [],
      titleExcel: 'Nhập excel dealsheet',
      urlExcel: BASE_URL + 'media/upload/dealsheet/Format_dealsheet.xlsx',
      onLoadingList: false,
      currentPage: 1,
      file: null,
      importItems: [],
      isBusy: false,
      fileName: '',
      fields: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'name',
          label: 'Tên chương trình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'targetMin',
          label: 'Điều kiện min (%)',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
        },
        {
          key: 'targetMax',
          label: 'Điều kiện max (%)',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-right',
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'isActive',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      fieldsErrors: [
        {
          key: 'id',
          label: 'ID',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'code',
          label: 'Code',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'name',
          label: 'Tên chương trình',
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
        },
        {
          key: 'startDate',
          label: 'Ngày bắt đầu',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'endDate',
          label: 'Ngày kết thúc',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        { key: 'actions' },
      ],
    };
  },
  props: ['dealshetIdDel'],
  watch: {
    dealshetIdDel: function () {
      this.fetchError();
    },
  },
  components: {
    UploadFileMulter,
  },
  computed: {
    hasError() {
      const errors = this.importItems.map((item) => item.errors).flat();
      return errors.length > 0;
    },
  },
  methods: {
    showDeleteAlert,
    formatDate,
    conFirmDel: function (row) {
      this.importItems = this.importItems.filter(
        (item) => item.code !== row.code,
      );
    },
    dealsheetTypeName: function (type) {
      return DEALSHEET_TYPE_NAME[type];
    },
    dealsheetStatusName: function (type) {
      return DEALSHEET_STATUS_NAME[type];
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    deleteDealSheet: function (data) {
      this.$emit('deleteAlert', data);
    },
    fetchError() {
      this.errorMessage = this.errorMessage.filter(
        (item) => item.id !== this.dealshetIdDel,
      );
    },
    viewDetails: function (id) {
      const { href } = this.$router.resolve({
        name: 'info-deal-sheet',
        query: { id: id },
      });
      window.open(href, '_blank');
    },
    hideModalExcel() {
      this.$refs['modal-deal-sheet-import'].hide();

      this.clearFileExcel();
    },
    onCreateDealSheetThrottle: _.throttle(function () {
      this.onCreateDealSheet();
    }, 2000),
    async onCreateDealSheet() {
      const items = cloneDeep(this.importItems);
      if (!this.isBusy) {
        try {
          const result = (await ApiService.post('v2/deal-sheets', items)).data;
          if (result.status === 200) {
            makeToastSuccess(result.message);
            this.hideModalExcel();
            this.$emit('onRefresh', true);
          }
        } catch (err) {
          const { message } = err.response.data;
          makeToastFaile(message);
        }
      }
    },
    rows() {
      return this.importItems.length;
    },
    htmlGetStatusValidate(item, type) {
      let name = '';
      switch (item.statusValidate) {
        case STATUS_VALIDATE.READY: {
          name =
            type === 'class' ? 'far fa-clock text-primary' : 'Sẵn sàng tạo mới';
          break;
        }
        case STATUS_VALIDATE.VALID: {
          name =
            type === 'class'
              ? 'fas fa-check-circle text-success'
              : 'Sẵn sàng tạo mới';
          break;
        }
        default: {
          name =
            type === 'class'
              ? 'fas fa-times-circle text-danger'
              : 'Không hợp lệ';
          break;
        }
      }
      return name;
    },
    getErrorMessage(error) {
      this.errorMessage = error || '';
    },
    getDataFromExcel(data, fileName) {
      this.errorMessage = [];
      this.fileName = fileName;
      this.importItems = [...data.rows].map((item, index) => {
        const statusValidate =
          item.errors && item.errors.length > 0
            ? STATUS_VALIDATE.INVALID
            : STATUS_VALIDATE.READY;
        return {
          ...item,
          index: index + 1,
          onLoading: false,
          statusValidate: statusValidate,
        };
      });
      this.onLoadingList = false;
    },
    clearFileExcel() {
      this.importItems = [];
      this.errorMessage = [];
      this.fileName = '';
      this.file = null;
      this.onLoadingList = false;
    },
  },
};
</script>
  
<style lang="scss">
.modal-deal-sheet-import {
  background-color:aqua table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }

  .modal-dialog.modal-xl {
    max-width: 95vw;
  }
}

#b-toaster-top-right {
  user-select: text;
  z-index: 999999999;
}
</style>